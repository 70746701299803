import { useQuery, useMutation } from 'react-query'
import { uniq } from 'lodash'

import auth from 'api'

import {
  addToCondition,
  parseFilters,
  parsePage,
  generateFilter,
} from 'helpers/api'

import { getUsers } from 'api/User/Users/queries'
import { getAssignments } from 'api/Package/SchoolAssignments/queries'
import { getCompletedAttemptsPost } from 'api/Package/Attempts/queries'

// ---------------------------------------------------------
export const getTeacherClassUsers = async ({ queryKey }) => {
  const [, classId, page, { filters = [], includeModels = [] } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (classId) {
    condition = addToCondition(condition, classId, 'class_id')
  }
  const { data: classUsers } = await auth({
    method: 'get',
    url: `auth/individual-teacher/students${condition}`,
  })

  let usersData
  let classUserWithData = classUsers
  if (includeModels?.includes('Users')) {
    const userIDs = classUsers?.items?.map((usr) => usr.user_id)
    const uniqIds = uniq(userIDs)
    if (uniqIds.length) {
      usersData = await getUsers({
        queryKey: [null, null, { users: uniqIds }],
      })
    } else return
    classUserWithData = {
      ...classUsers,
      items: classUsers.items.reduce((acc, schUsr) => {
        let matchedUser = usersData?.items?.find(
          (usr) => usr.id === schUsr.user_id
        )
        return [...acc, { ...schUsr, User: matchedUser ?? null }]
      }, []),
    }
  }
  let assignmentsData
  if (includeModels?.includes('Assignments')) {
    const assignmentFilters = generateFilter([], classId, 'class_id')
    assignmentsData = await getAssignments({
      queryKey: [null, null, { filters: assignmentFilters }],
    })
    let attemptsData = []

    if (assignmentsData?.items.length) {
      const userIDs = classUsers?.items.map((usr) => usr.user_id)
      const uniqUserIds = uniq(userIDs)

      const activityIDs = assignmentsData?.items.map(
        (assignment) => assignment.activity_id
      )

      const uniqactivityIds = uniq(activityIDs)

      attemptsData = await getCompletedAttemptsPost({
        // sub_school_id: classUsers?.items[0].Class.SchoolYear.sub_school_id,
        page: 1,
        user_id: uniqUserIds,
        activity_id: uniqactivityIds,
      })
    }

    classUserWithData = {
      ...classUserWithData,
      items: classUserWithData.items.reduce((acc, usr) => {
        let idx = attemptsData.findIndex((att) => att.user_id === usr.user_id)

        if (idx !== -1)
          return [
            ...acc,
            {
              ...usr,
              Attempts: {
                totalAssignments: assignmentsData.total_items,
                attempts: attemptsData[idx].count,
              },
            },
          ]
        else
          return [
            ...acc,
            {
              ...usr,
              Attempts: {
                totalAssignments: assignmentsData.total_items,
                attempts: 0,
              },
            },
          ]
      }, []),
    }
  }

  return classUserWithData
}

export const useTeacherClassUsers = (
  classId,
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'teacherClassUsers',
      classId,
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getTeacherClassUsers,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const createTeacherClassUser = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/individual-teacher/students`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateTeacherClassUser = ({ queryOptions } = {}) => {
  return useMutation(createTeacherClassUser, {
    ...queryOptions,
  })
}
// -----------------------------------

export const createTeacherUserClasses = async ({ classes, id: userId }) => {
  let promiseArray
  let res
  if (classes?.length) {
    promiseArray = classes.map((classId) =>
      createTeacherClassUser({
        user_id: userId,
        class_id: classId,
      })
    )
    res = await Promise.allSettled(promiseArray)
  }
  return res
}
export const useCreateTeacherUserClasses = ({ queryOptions } = {}) => {
  return useMutation(createTeacherUserClasses, {
    ...queryOptions,
  })
}
// -----------------------------------

export const createTeacherClassUsers = async ({ users, id: classId }) => {
  let promiseArray
  let res
  if (users?.length) {
    promiseArray = users.map((userId) =>
      createTeacherClassUser({
        user_id: userId,
        class_id: classId,
      })
    )
    res = await Promise.allSettled(promiseArray)
  }
  return res
}
export const useCreateClassUsers = ({ queryOptions } = {}) => {
  return useMutation(createTeacherClassUsers, {
    ...queryOptions,
  })
}
// ---------------------------------------------------------
export const enrollTeacherClassUser = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `/auth/individual-teacher/students/enroll`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useEnrollTeacherClassUser = ({ queryOptions } = {}) => {
  return useMutation(enrollTeacherClassUser, {
    ...queryOptions,
  })
}
// ---------------------------------------------------------

export const deleteTeacherClassUser = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/individual-teacher/students/${id}`,
  })
}
export const useDeleteTeacherClassUser = ({ queryOptions } = {}) => {
  return useMutation(deleteTeacherClassUser, {
    ...queryOptions,
  })
}
